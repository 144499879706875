import { FC } from 'react';

import Image from 'next/image';

import { css } from '@emotion/css';
import clsx from 'clsx';

import { Avatar } from '~components/common/avatar';
import {
  Carousel,
  CarouselGap,
  CarouselVariant,
} from '~components/common/carousel/carousel';
import { imageLoader, isSupportBlur, getBlurImageUrl } from '~lib/image-loader';

export interface ITalentCarouselItem {
  clientName: string;
  country: string;
  id: string;
  projectImageUrl: string;
  projectTitle: string;
  projectLink?: string;
  talentImageUrl: string;
  talentTitle: string;
  talentName: string;
}

export interface FeaturedCarouselProps {
  talentCarouselItems: ITalentCarouselItem[];
}

export const FeaturedCarousel: FC<
  FeaturedCarouselProps & { className?: string }
> = ({ talentCarouselItems, className }) => {
  if (!talentCarouselItems.length) {
    return null;
  }

  return (
    <Carousel
      title="featured talent"
      className={className}
      gap={CarouselGap.Featured}
      variant={CarouselVariant.Featured}
    >
      {talentCarouselItems.map((item) => (
        <TalentCarouselItem key={item.id} {...item} />
      ))}
    </Carousel>
  );
};

const TalentCarouselItem: FC<ITalentCarouselItem> = ({
  clientName,
  country,
  id,
  projectImageUrl,
  projectTitle,
  projectLink = `${process.env.NEXT_PUBLIC_APPLICATION_URL}/signup`,
  talentImageUrl,
  talentTitle,
  talentName,
}) => {
  return (
    <Carousel.Panel key={id}>
      <a
        className="relative block h-[360px] overflow-hidden rounded-2xl bg-layout md:h-[520px]"
        href={projectLink}
      >
        <section
          className={clsx(
            'relative z-10 flex h-full flex-col justify-end p-6 capitalize text-white ',
            css`
              background: linear-gradient(
                360deg,
                rgba(0, 0, 0, 0.8) 0%,
                rgba(0, 0, 0, 0) 55%
              );
              @media (min-width: 768px) {
                background: linear-gradient(
                  360deg,
                  rgba(0, 0, 0, 0.8) 0%,
                  rgba(0, 0, 0, 0) 38%
                );
              }
            `,
          )}
        >
          <h3 className="p300">{clientName}</h3>
          <h2 className="h200 mt-[2px]">{projectTitle}</h2>
          <hr className="my-4" />
          <div className="flex items-center gap-4">
            <Avatar
              src={talentImageUrl}
              userName={talentName}
              className="!h-10 !w-10 shrink-0"
            />
            <div>
              <h3 className="p300-b">{talentName}</h3>
              <h3 className="p100 mt-[2px]">{talentTitle}</h3>
            </div>
            <p className="c100 ml-auto flex h-6 w-auto items-center justify-center rounded-full border border-white px-2">
              {country}
            </p>
          </div>
        </section>
        <figure className="absolute top-0 left-0 z-0 flex h-full w-full brightness-[.98]">
          <Image
            alt={projectTitle}
            src={projectImageUrl}
            loader={imageLoader}
            placeholder={isSupportBlur(projectImageUrl) ? 'blur' : 'empty'}
            blurDataURL={getBlurImageUrl(projectImageUrl)}
            className="next-image flex-grow object-cover"
            objectFit="cover"
            layout="fill"
            crossOrigin="anonymous"
          />
        </figure>
      </a>
    </Carousel.Panel>
  );
};
