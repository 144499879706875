import React, { ReactNode, useState } from 'react';

import styled from '@emotion/styled';

import { useStepper } from '../../../lib/hooks/use-stepper';

export interface CarouselProps {
  title: string;
  children: ReactNode[];
  className?: string;
  variant?: CarouselVariant;
  gap?: CarouselGap;
  spacerClassName?: string;
}

export enum CarouselVariant {
  Small,
  Medium,
  Large,
  Featured,
}

export enum CarouselGap {
  Small,
  Medium,
  Large,
  Featured,
}

const variantsConfig = {
  [CarouselVariant.Small]: {
    mobileWidth: 180,
    desktopWidth: 180,
  },
  [CarouselVariant.Medium]: {
    mobileWidth: 204,
    desktopWidth: 204,
  },
  [CarouselVariant.Large]: {
    mobileWidth: 320,
    desktopWidth: 384,
  },
  [CarouselVariant.Featured]: {
    mobileWidth: 311,
    desktopWidth: 550,
  },
};

const gapConfig = {
  [CarouselGap.Small]: 16,
  [CarouselGap.Medium]: 16,
  [CarouselGap.Featured]: 40,
  [CarouselGap.Large]: 48,
};

const getFeaturedGapValue = (gap: CarouselGap, variant: CarouselVariant) =>
  variant === CarouselVariant.Featured
    ? Math.ceil(gapConfig[gap] / 2)
    : gapConfig[gap];

const CarouselContainer = styled.article<{
  gap: CarouselGap;
  variant: CarouselVariant;
}>`
  .carousel-panel {
    width: ${({ variant }) => variantsConfig[variant].mobileWidth}px;
    @media (min-width: 768px) {
      width: ${({ variant }) => variantsConfig[variant].desktopWidth}px;
    }
  }

  .panels-container {
    gap: ${({ gap }) => gapConfig[gap]}px;

    @media (max-width: 768px) {
      gap: ${({ gap, variant }) => `${getFeaturedGapValue(gap, variant)}px`};
    }
  }
`;

export const Carousel = ({
  children,
  title,
  className = '',
  variant = CarouselVariant.Large,
  gap = CarouselGap.Small,
}: CarouselProps) => {
  const [currentStep, { nextStep, prevStep }] = useStepper();
  const [carouselRef, setCarouselRef] = useState<HTMLDivElement>();

  const PANEL_WIDTH = variantsConfig[variant]?.desktopWidth;
  const GAP_BETWEEN_PANELS = gapConfig[gap];
  const calculatedTransformInPX =
    currentStep * -(PANEL_WIDTH + GAP_BETWEEN_PANELS);
  const nextDisabled =
    carouselRef?.scrollWidth <=
    Math.abs(calculatedTransformInPX) + carouselRef?.clientWidth;
  const prevDisabled = currentStep === 0;

  const scrollToNextOrPreviousPanel = (isNext = true) => {
    const elm = carouselRef;
    const toScroll = PANEL_WIDTH + GAP_BETWEEN_PANELS;
    elm?.scrollTo({
      left: isNext ? elm?.scrollLeft + toScroll : elm?.scrollLeft - toScroll,
      behavior: 'smooth',
    });
  };

  return (
    <CarouselContainer variant={variant} gap={gap} className={className}>
      <div className="flex w-full items-center justify-between">
        <h2 className="h300 first-letter:capitalize md:mr-6">{title}</h2>
        <div className="flex items-center">
          <button
            className="mr-3 hidden bg-white fill-current p-0 text-black disabled:cursor-not-allowed disabled:opacity-25 md:block"
            disabled={prevDisabled}
            onClick={() => {
              prevStep();
              scrollToNextOrPreviousPanel(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <rect
                width="27"
                height="2"
                transform="matrix(-1 0 0 1 30 15)"
                fill="black"
              />
              <path d="M13 4H16L4 16L16 28H13L1 16L13 4Z" fill="black" />
            </svg>
          </button>
          <button
            disabled={nextDisabled}
            className="hidden bg-white p-0 text-black disabled:cursor-not-allowed disabled:opacity-25 md:block"
            onClick={() => {
              nextStep();
              scrollToNextOrPreviousPanel();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <rect x="2" y="15" width="27" height="2" fill="black" />
              <path d="M19 4H16L28 16L16 28H19L31 16L19 4Z" fill="black" />
            </svg>
          </button>
        </div>
      </div>
      <div
        ref={setCarouselRef}
        className="mt-8 flex overflow-x-scroll transition-all duration-300 md:overflow-x-hidden"
      >
        <div className="panels-container flex">{children}</div>
      </div>
    </CarouselContainer>
  );
};

Carousel.Panel = ({ children }: { children: ReactNode }) => {
  return <div className="carousel-panel shrink-0">{children}</div>;
};

Carousel.Gap = CarouselGap;
Carousel.Variant = CarouselVariant;
