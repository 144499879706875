import React from 'react';

import { useBasicDetails } from './use-basic-details';

interface UseEducationFlagOptions {
  key?: string;
}

interface UseEducationFlagReturn {
  show: boolean;
  markAsSeen: () => void;
}

export const useEducationFlag: (
  args: UseEducationFlagOptions,
) => UseEducationFlagReturn = ({ key = '' }: UseEducationFlagOptions = {}) => {
  const [show, setShow] = React.useState(false);
  const { userId } = useBasicDetails();

  const formattedKey = React.useMemo(
    () => `togetherr-education-flags-${key}-${userId}`,
    [key, userId],
  );

  const markAsSeen = React.useCallback(() => {
    localStorage.setItem(formattedKey, 'true');
  }, [formattedKey]);

  React.useEffect(() => {
    if (typeof window === 'undefined' || !userId) return;

    const value = localStorage.getItem(formattedKey);

    if (!value) {
      // Unless the key exists we know to show the education to the user
      setShow(true);
    }
  }, [formattedKey, userId]);

  return {
    show,
    markAsSeen,
  };
};
