import { useState } from 'react';

interface StepperActions {
  nextStep(): void;

  prevStep(): void;

  setStepIndex(index: number): void;
}

export const useStepper = (
  initialState = 0,
): [page: number, stepperActions: StepperActions] => {
  const [stepIndex, setStepIndex] = useState(initialState);

  const nextStep = () => {
    setStepIndex((prev) => prev + 1);
  };

  const prevStep = () => {
    setStepIndex((prev) => prev - 1);
  };

  return [stepIndex, { nextStep, prevStep, setStepIndex }];
};
