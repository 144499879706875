import { ReactNode, RefObject } from 'react';

import styled from '@emotion/styled';
import { m, MotionValue, useTransform } from 'framer-motion';
import { useInViewScroll } from 'framer-motion-hooks';

const Word = styled(m.span)`
  display: inline-block;
  --letter-weight: 100;
  font-variation-settings: 'wght' var(--letter-weight);
`;

export const AnimatedWord = ({
  children,
  elementScrollRef,
  className,
}: {
  children: ReactNode;
  elementScrollRef: RefObject<HTMLElement>;
  className?: string;
}) => {
  const trackElementScrollProgress = useInViewScroll(elementScrollRef);
  const letterWeight = useTransform(
    trackElementScrollProgress as unknown as MotionValue,
    [0, 0.2],
    [100, 900],
  );

  return (
    <Word
      style={
        {
          '--letter-weight': letterWeight,
        } as any
      }
      className={className}
    >
      {children}
    </Word>
  );
};
