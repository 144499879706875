export const TEAM_IMAGE_SRC =
  'https://cdn.filestackcontent.com/bPSOgRjGTjOSJsvu6nSA';
export const VETTING_IMAGE_SRC =
  'https://cdn.filestackcontent.com/tL08r716Q5iG3rtdxztM';
export const SPEED_OF_AI_IMAGE_SRC =
  'https://cdn.filestackcontent.com/5Gv3eFwMSfifsXI9Y1Sv';
export const ONE_POINT_IMAGE_SRC =
  'https://cdn.filestackcontent.com/kSzFZuvwTuCd6eYJFRZL';
export const FAIRLY_PRICED_IMAGE_SRC =
  'https://cdn.filestackcontent.com/NtjEd7mPRASYF8vO4YXA';
export const END_TO_END_IMAGE_SRC =
  'https://cdn.filestackcontent.com/oGQ4caqBQyGsYkNT8IdK';
export const LOVED_BY_PROCUREMENT_IMAGE_SRC =
  'https://cdn.filestackcontent.com/gPfEa4pVRIGbsZEYWH5Y';
export const RIGHT_SKILLS_IMAGE_SRC =
  'https://cdn.filestackcontent.com/2yKtDwBSDeBne0Z5M2YI';
export const DESIGNED_BY_PEERS_IMAGE_SRC =
  'https://cdn.filestackcontent.com/zv7acpHwS6aJMZMBBQch';
export const FOR_BRANDS_BOTTOM_MOBILE_IMAGE_SRC =
  'https://cdn.filestackcontent.com/U9snkcZLTBY6L8youMkd';
export const FOR_BRANDS_BOTTOM_IMAGE_SRC =
  'https://cdn.filestackcontent.com/DaIDQF75TgSMaLk1AVdE';
