import dynamic from 'next/dynamic';

import { Builder } from '@builder.io/react';

if (!process.env.NEXT_PUBLIC_BUILDER_API_KEY) {
  throw new Error(
    'Missing environment variable NEXT_PUBLIC_BUILDER_API_KEY, Add your public BuilderIO key to env file',
  );
}

export const builderConfig = {
  apiKey: process.env.NEXT_PUBLIC_BUILDER_API_KEY,
};

// TODO: check if works and use these in builder

// Register some components to be used in the drag and drop editor
// https://www.builder.io/c/docs/custom-components-setup
Builder.registerComponent(
  // We dynamically import components so they are only downloaded in the browser
  // when used
  dynamic(async () => {
    const mod = await import('~components/guests/feature');
    return mod.Feature;
  }),
  {
    name: 'Feature',
    // image: 'https://tabler-icons.io/static/tabler-icons/icons-png/icons.png',
    inputs: [
      {
        name: 'image',
        type: 'string',
        defaultValue: '',
      },
      {
        name: 'alt',
        type: 'string',
        defaultValue: 'I am the alt for the image',
      },
      {
        name: 'title',
        type: 'string',
        defaultValue: 'I am the tile',
      },
      {
        name: 'flip',
        type: 'boolean',
        defaultValue: 'flip my side',
      },
      {
        name: 'className',
        type: 'text',
        defaultValue: 'some-class-name',
      },
    ],
    canHaveChildren: true,
  },
);
