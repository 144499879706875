import Image from 'next/image';

import clsx from 'clsx';

export const Feature: React.FC<{
  image: string;
  title: string;
  alt: string;
  flip?: boolean;
  className?: string;
}> = ({ image, title, children, alt, flip, className }) => {
  return (
    <article
      className={clsx('lg:flex lg:items-center lg:justify-evenly', className)}
    >
      <figure
        className={clsx('flex-1 overflow-hidden lg:max-w-[520px]', {
          'lg:order-2': flip,
        })}
      >
        <Image
          src={image}
          layout="responsive"
          objectFit="contain"
          loading="lazy"
          width={640}
          height={640}
          alt={alt}
        />
      </figure>
      <div
        className={clsx('mt-10 flex-1 lg:max-w-[520px]', {
          'lg:order-1': flip,
        })}
      >
        <h3 className="h100 lg:h300">{title}</h3>
        <p className="p300 mt-4 md:mt-8">{children}</p>
      </div>
    </article>
  );
};
