export const Stat: React.FC<{
  heading: string;
}> = ({ heading, children }) => {
  return (
    <div className="flex flex-col justify-start rounded-2xl bg-layout-darkest p-6 lg:p-10">
      <h4 className="h300 md:h500">{heading}</h4>
      <p className="p300-b mt-2">{children}</p>
    </div>
  );
};
