import * as React from 'react';

import { builder } from '@builder.io/react';
import { css } from '@emotion/css';
import clsx from 'clsx';
import { NextSeo, OrganizationJsonLd } from 'next-seo';
import ReactPlayer from 'react-player';

import { Button } from '~components/common/button/button';
import { ContentArea } from '~components/common/content-area';
import { GuestFooter } from '~components/common/guest-footer';
import { GuestHeader } from '~components/common/guest-header/guest-header';
import { TestimonialsCarousel } from '~components/common/testimonials-carousel/testimonials-carousel';
import { Benefit } from '~components/guests/benefit';
import { Feature } from '~components/guests/feature';
import { Stat } from '~components/guests/stat';

import IntersectSvg from '/public/how-it-works/intersect.svg';
import DiamondSvg from '/public/how-it-works/diamond.svg';
import SquareSvg from '/public/how-it-works/square.svg';
import AdWeekLogo from '/public/home/adweek_logo.svg';
import BILogo from '/public/home/business_insider_logo.svg';
import TheDrumLogo from '/public/home/the_drum_logo.svg';
import AdAgeLogo from '/public/home/adage-logo.svg';
import ContLogo from '/public/home/cont-logo.svg';

import { AnimatedWord } from '~components/home/animated-word';
import {
  ITalentCarouselItem,
  FeaturedCarousel,
} from '~components/talent/featured-carousel/featured-carousel';
import { builderConfig } from '~lib/builder-config';
import { serialize } from '~lib/http/request';
import { dispatchAnalyticsEvent, Events } from '~lib/loggers/events-recorder';
import { TEAM_IMAGE_SRC } from '~static-data/filestack-image-constants';

builder.init(builderConfig.apiKey);

const fullHeightCss = css`
  height: calc(100vh - 88px);
`;

export const getStaticProps = async () => {
  const { communityStats, testimonials, sectionTextData, talentCarouselData } =
    await getBuilderData();

  if (!communityStats?.length || !testimonials?.length || !sectionTextData) {
    return {
      notFound: true,
    };
  }

  return {
    props: serialize({
      communityStats,
      testimonials,
      sectionTextData,
      talentCarouselData,
    }),
    revalidate: 5,
  };
};

const Home: React.FC<{
  communityStats: CommunityStat[];
  testimonials: Testimonial[];
  sectionTextData: SectionTextData;
  talentCarouselData: ITalentCarouselItem[];
}> = ({
  communityStats,
  testimonials,
  sectionTextData,
  talentCarouselData,
}) => {
  const heroSectionRef = React.useRef();
  return (
    <>
      <NextSeo
        title="togetherr: Build your creative dream-team in minutes"
        description="togetherr is a global talent platform that connects and curates world class creative teams with the world’s most innovative brands. Build your creative team within 24 hours."
        canonical={`${process.env.NEXT_PUBLIC_APPLICATION_URL}/`}
      />
      <OrganizationJsonLd
        name="togetherr"
        url={process.env.NEXT_PUBLIC_APPLICATION_URL}
        logo="/common/svg/togetherr-fiverr-company.svg"
        sameAs={[
          'https://www.linkedin.com/company/togetherr/',
          'https://twitter.com/togetherrcom',
        ]}
        parentOrganization={{ name: 'Fiverr', url: 'https://fiverr.com' }}
      />
      <GuestHeader />
      <main className="overflow-hidden">
        <section
          id="hero"
          className={clsx(
            'relative flex flex-col items-center justify-center bg-black text-white',
            fullHeightCss,
          )}
          ref={heroSectionRef}
        >
          <div className="px-4">
            <h1 className="h500 text-[50px] xs:text-[60px] md:text-center md:text-[64px] lg:h550 lg:max-w-5xl lg:text-[120px]">
              Build your creative <br />
              {`dream-team `}
              <AnimatedWord elementScrollRef={heroSectionRef}>
                in minutes
              </AnimatedWord>
            </h1>
            <h2 className="p300 mt-6 md:p500 md:mx-auto md:max-w-[550px] md:text-center">
              {sectionTextData.heroTagline}
            </h2>
            <div className="mt-6 md:mx-auto md:max-w-md lg:mt-12">
              <div className="lg:flex lg:justify-between">
                <Button
                  theme={Button.Theme.White}
                  size={Button.Size.Large}
                  className="mt-10 h-16 w-full lg:hidden"
                  href="/signup"
                >
                  sign up →
                </Button>
                <Button
                  theme={Button.Theme.White}
                  size={Button.Size.Large}
                  className="mt-10 hidden h-16 w-full lg:flex lg:w-[212px]"
                  href="/project-setup"
                  onClick={() => {
                    dispatchAnalyticsEvent({
                      event: Events.START_PROJECT,
                      position: 'top',
                    });
                  }}
                >
                  start a project →
                </Button>
                <Button
                  size={Button.Size.Large}
                  fill={Button.Fill.Ghost}
                  theme={Button.Theme.White}
                  className="mt-4 h-16 w-full lg:mt-10 lg:w-[212px]"
                  href="/demo"
                >
                  book a demo
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section id="video">
          <ContentArea className="relative mt-10 items-center justify-center px-4 md:mt-20 md:px-14">
            <div className="aspect-w-16 aspect-h-9 relative overflow-hidden rounded-2xl">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=RWEHC6evlu4"
                className={clsx('absolute top-0 left-0')}
                width="100%"
                height="100%"
                light="/landing-page/video-placeholder.png"
                controls
                onClickPreview={() =>
                  dispatchAnalyticsEvent({
                    event: Events.VIDEO_PREVIEW_CLICK,
                  })
                }
                playIcon={
                  <div className="group relative grid h-12 w-12 place-content-center rounded-full bg-white drop-shadow-2xl md:h-24 md:w-24">
                    <div
                      className={clsx(
                        'ml-2 h-5 w-5 bg-black shadow-xl transition-colors group-hover:opacity-70 md:ml-4 md:h-10 md:w-10',
                        css`
                          clip-path: polygon(0 0, 0% 100%, 85% 50%);
                        `,
                      )}
                    />
                  </div>
                }
              />
            </div>
          </ContentArea>
        </section>
        <section id="collaboration">
          <ContentArea className="px-4 md:px-14">
            <h2 className="h300 flex h-[350px] items-center md:h500 md:h-[520px] md:min-h-[200px] md:py-0 md:text-center">
              {sectionTextData.midSectionText}
            </h2>
          </ContentArea>
        </section>
        {talentCarouselData?.length >= 3 && (
          <ContentArea className="relative pl-4 md:mt-20 md:px-14 lg:mb-[6.25vh]">
            <FeaturedCarousel talentCarouselItems={talentCarouselData} />
          </ContentArea>
        )}
        <section id="as-seen-on">
          <ContentArea className="mt-20 flex flex-col items-center justify-center px-4 md:mt-36 md:px-14">
            <h2 className="h200">As seen in</h2>
            <div className="mt-4 flex flex-wrap justify-around md:mt-8 md:gap-24">
              <a
                href="https://adage.com/article/special-report-creativity-top-5/top-5-creative-campaigns-july-5-2022/2422466"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AdAgeLogo />
              </a>
              <a
                href="https://www.contagious.com/news-and-views/changing-the-business-of-creativity"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContLogo />
              </a>
              <a
                href="https://www.businessinsider.com/fiverr-launches-creative-consulting-business-for-enterprise-clients-2022-6"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BILogo />
              </a>
              <a
                href="https://www.adweek.com/commerce/fiverr-challenges-ad-agencies-with-creative-team-builder-tool/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AdWeekLogo />
              </a>
              <a
                href="https://www.thedrum.com/news/2022/06/09/fiverr-launches-togetherr-it-s-fantasy-football-meets-advertising"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TheDrumLogo />
              </a>
            </div>
          </ContentArea>
        </section>
        <section id="swat-team">
          <ContentArea className="my-20 px-4 lg:px-14">
            <Feature
              image={TEAM_IMAGE_SRC}
              title={sectionTextData.rightSideImageText.title}
              alt="team"
            >
              {sectionTextData.rightSideImageText.description}
            </Feature>
          </ContentArea>
        </section>
        <section id="world-class">
          <ContentArea className="my-20 px-4 lg:px-14">
            <Feature
              image="/home/talents.png"
              title={sectionTextData.leftSideImageText.title}
              alt="team"
              flip
            >
              <span>
                {sectionTextData.leftSideImageText.description}
                <Button
                  size={Button.Size.Large}
                  className="mt-10 hidden h-16 w-full lg:mt-8 lg:flex lg:w-52"
                  href="/project-setup"
                  onClick={() => {
                    dispatchAnalyticsEvent({
                      event: Events.START_PROJECT,
                      position: 'middle',
                    });
                  }}
                >
                  start project →
                </Button>
                <Button
                  size={Button.Size.Large}
                  className="mt-10 h-16 w-full lg:hidden"
                  href="/signup"
                >
                  sign up →
                </Button>
              </span>
            </Feature>
          </ContentArea>
        </section>
        <section id="benefits">
          <ContentArea className="mt-20 px-6 pb-20 md:px-12 lg:px-14 lg:pb-30">
            <div className="grid grid-cols-1 gap-y-20 md:mt-12 md:grid-cols-2 md:gap-x-8 md:gap-y-14 lg:grid-cols-3 lg:gap-x-15">
              <Benefit
                title={sectionTextData.threeIconPoints?.[0].point.title}
                icon={<IntersectSvg className="h-12 w-12" />}
              >
                {sectionTextData.threeIconPoints?.[0].point.description}
              </Benefit>
              <Benefit
                title={sectionTextData.threeIconPoints?.[1].point.title}
                icon={<DiamondSvg className="h-12 w-12" />}
              >
                {sectionTextData.threeIconPoints[1].point.description}
              </Benefit>
              <Benefit
                title={sectionTextData.threeIconPoints[2].point.title}
                icon={<SquareSvg className="h-12 w-12" />}
              >
                {sectionTextData.threeIconPoints[2].point.description}
              </Benefit>
            </div>
          </ContentArea>
        </section>
        <section id="testimonials" className="bg-black">
          <ContentArea className="px-6 py-20 text-white md:px-12 lg:flex lg:items-center lg:py-30 lg:px-14 xl:gap-30">
            <TestimonialsCarousel>
              {testimonials?.map(({ id, author, image, quote }) => (
                <TestimonialsCarousel.Testimonial
                  key={id}
                  author={author}
                  imgSrc={`${image}?width=450&quality=75`}
                >
                  {quote}
                </TestimonialsCarousel.Testimonial>
              ))}
            </TestimonialsCarousel>
          </ContentArea>
        </section>
        <section id="community" className="-my-px bg-black text-white">
          <ContentArea className="mt-20 px-4 pb-20 md:px-12 lg:mt-10 lg:px-14 lg:pb-52">
            <h2 className="h100 lg:h500">Our community</h2>
            <div className="mt-8 grid grid-cols-2 gap-4 lg:mt-18 lg:grid-cols-3 lg:gap-6">
              {communityStats.map(({ stat, subtitle }) => (
                <Stat key={stat + subtitle} heading={stat}>
                  {subtitle}
                </Stat>
              ))}
            </div>
          </ContentArea>
        </section>
      </main>
      <GuestFooter />
    </>
  );
};

// CMS Data:
const BUILDER_MODEL_NAMES = {
  stats: 'homepage-community-stats', // https://builder.io/models/9a8770e572354a768700952ca1aab0ac
  testimonials: 'homepage-testimonials', // https://builder.io/models/9df4f2d2632647e9a9b8a10cb58069fe
  sectionsTextData: 'home-page-sections-text-data', // https://builder.io/models/478dc8009a394e679d54c046bbba50f3
  talentCarouselData: 'homepage-featured-talent-carousel', // https://builder.io/models/bb34ec3589b34619b769c9288151e7b4
};
interface CommunityStat {
  id: string;
  stat: string;
  subtitle: string;
}

interface Testimonial {
  id: string;
  author: string;
  company?: string;
  image?: string;
  quote?: string;
}

export interface SectionTextData {
  heroTagline?: string;
  leftSideImageText?: ImageText;
  midSectionText?: string;
  rightSideImageText?: ImageText;
  threeIconPoints?: {
    point?: ImageText;
  }[];
}

export interface ImageText {
  description?: string;
  title?: string;
}

interface TalentCarouselItem {
  clientName: string;
  country: string;
  projectImage: string;
  projectTitle: string;
  talentImage: string;
  talentName: string;
  talentTitle: string;
}

interface TalentCarouselResultData {
  carouselItems: Array<{ item: TalentCarouselItem }>;
}

const getBuilderData = async () => {
  const communityStatsPromise = builder.getAll(BUILDER_MODEL_NAMES.stats);
  const testimonialsPromise = builder.getAll(BUILDER_MODEL_NAMES.testimonials);
  const sectionTextDataPromise = builder.getAll(
    BUILDER_MODEL_NAMES.sectionsTextData,
  );
  const carouselDataPromise = builder.getAll(
    BUILDER_MODEL_NAMES.talentCarouselData,
  );
  const builderData = await Promise.all([
    communityStatsPromise,
    testimonialsPromise,
    sectionTextDataPromise,
    carouselDataPromise,
  ]);

  const [
    communityStatsResults,
    testimonialResults,
    sectionTextDataResults,
    carouselDataResults,
  ] = builderData;

  const communityStats = communityStatsResults.map(
    (result) =>
      ({ ...result?.data, id: result.id } as unknown as CommunityStat),
  );

  const testimonials = testimonialResults.map(
    (result) =>
      ({
        ...result?.data,
        // set the image quality and width via builder image api
        image: `${result.data?.image}?width=450&quality=75`,
        id: result.id,
      } as unknown as Testimonial),
  );

  // Builder results are always an array - we just have one that we want here
  // note that we can change this if we want to test variations and such.
  const sectionTextData = sectionTextDataResults[0]?.data;

  const talentCarouselData = (
    carouselDataResults?.[0]?.data as unknown as TalentCarouselResultData
  )?.carouselItems.map(
    ({
      item: { talentName, talentImage, projectImage, ...rest },
    }): ITalentCarouselItem => ({
      // make an Id from the model ID + the talent name and trim white space
      id: (carouselDataResults?.[0].id + talentName).replace(/\s/g, ''),
      talentName,
      talentImageUrl: talentImage,
      projectImageUrl: projectImage,
      ...rest,
    }),
  );

  return {
    communityStats,
    testimonials,
    sectionTextData,
    talentCarouselData,
  };
};

export default Home;
