import React from 'react';

export const Benefit: React.FC<{
  icon?: JSX.Element;
  title: string;
  className?: string;
}> = ({ icon, title, children, className }) => {
  return (
    <article className={className}>
      {icon}
      <h4 className="h100 mt-4 lg:h300">{title}</h4>
      <p className="p300 mt-2 md:mt-6">{children}</p>
    </article>
  );
};
