import React from 'react';

import Image from 'next/image';

import clsx from 'clsx';
import { m } from 'framer-motion';

import { useContainerDimensions } from '~lib/hooks';

import ArrowRight from 'public/common/svg/arrow-right.svg';

export const TestimonialsCarousel: React.FC & {
  Testimonial: React.ElementType;
} = ({
  children,
  className,
}: {
  children: React.ReactChildren;
  className?: string;
}) => {
  const containerRef = React.useRef();
  const [position, setPosition] = React.useState(0);
  const { width } = useContainerDimensions(containerRef);

  const totalCount = React.useMemo(
    () => React.Children.toArray(children).length,
    [children],
  );

  const totalWidth = React.useMemo(
    () => width * totalCount,
    [width, totalCount],
  );

  const moveForward = React.useCallback(() => {
    setPosition((prevPosition) =>
      Math.abs(prevPosition) < totalWidth - width
        ? prevPosition - width
        : prevPosition,
    );
  }, [totalWidth, width]);

  const moveBack = React.useCallback(() => {
    setPosition((prevPosition) =>
      prevPosition < 0 ? prevPosition + width : 0,
    );
  }, [width]);

  return (
    <div className={clsx(className, 'flex w-full flex-col')} ref={containerRef}>
      <div
        className={clsx('flex items-center md:order-2 md:mt-8', {
          hidden: totalCount === 1,
        })}
      >
        <div className="inline-flex">
          <button onClick={moveBack}>
            <ArrowRight className="h-6 w-6 rotate-180" />
          </button>
          <button className="ml-6" onClick={moveForward}>
            <ArrowRight className="h-6 w-6" />
          </button>
        </div>
        <div className="c100 ml-6">
          <span>{Math.abs(position) / width + 1}</span>
          <span className="px-2 opacity-40">/</span>
          <span className="opacity-40">{totalCount}</span>
        </div>
      </div>
      <div
        className={clsx(
          'w-full overflow-x-hidden overflow-y-hidden',
          totalCount > 1 && 'mt-8 md:mt-0',
        )}
      >
        <m.div
          className="flex"
          animate={{ x: position }}
          transition={{ type: 'spring', duration: 0.5 }}
        >
          {children}
        </m.div>
      </div>
    </div>
  );
};

const MISSING_AVATAR_IMAGE = '/common/svg/missing-avatar.svg';

const Testimonial: React.FC<{
  author: string;
  company: React.ReactNode;
  imgSrc: string;
}> = ({ children, author, company, imgSrc = MISSING_AVATAR_IMAGE }) => {
  return (
    <div className="w-full min-w-full px-px lg:flex lg:items-center lg:gap-20">
      <div
        className={clsx(
          'testimonial__image',
          'h-30 w-30 overflow-hidden rounded-2xl',
          'lg:hidden',
        )}
      >
        <Image
          src={imgSrc}
          width={120}
          height={120}
          alt={`image of ${author}`}
          layout="responsive"
          objectFit="cover"
        />
      </div>
      <div className="testimonial__image hidden flex-1 lg:block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 450 450"
          fill="none"
          className="lg:w-[25vw]"
        >
          <defs>
            <clipPath id="blob">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M326.801 16C326.801 7.16344 319.638 0 310.801 0H16C7.16344 0 0 7.16346 0 16V310.801C0 319.638 7.16347 326.801 16 326.801H72.1844C81.021 326.801 88.1844 333.965 88.1844 342.801V434C88.1844 442.837 95.3479 450 104.184 450H434C442.837 450 450 442.837 450 434V104.184C450 95.3479 442.837 88.1844 434 88.1844H342.801C333.965 88.1844 326.801 81.021 326.801 72.1844V16Z"
                fill="#D9D9D9"
              />
            </clipPath>
          </defs>
          <image
            x="0"
            y="0"
            width="100%"
            height="100%"
            clipPath="url(#blob)"
            preserveAspectRatio="xMidYMid slice"
            xlinkHref={imgSrc}
          ></image>
        </svg>
      </div>
      <div className="">
        <blockquote className="h100 mt-6 lg:text-4xl">“{children}”</blockquote>
        <p className="c100 mt-4 inline-flex items-center leading-[1.4] md:mt-7">
          <span>{author}</span>
          {!!company && <span className="px-4">|</span>}
          <span>{company}</span>
        </p>
      </div>
    </div>
  );
};

TestimonialsCarousel.Testimonial = Testimonial;
